<template>
  <div class="pageBox">
    <div
      class="page-list personnelfiles flex-1"
      v-if="pageStatus=='list'"
    >
      <!-- tabs -->
      <template>
        <div class="saleslip_tabs">
          <a-tabs
            default-active-key="1"
            @change="callback"
            class="default_tabs"
            v-model="shoptype"
          >
            <a-tab-pane
              key="1"
              tab="全部"
            >
            </a-tab-pane>
            <a-tab-pane
              key="2"
              tab="待支付"
              force-render
            >
            </a-tab-pane>
            <a-tab-pane
              key="3"
              tab="待发货"
            >
            </a-tab-pane>
            <a-tab-pane
              key="4"
              tab="待收货"
            >
            </a-tab-pane>
            <a-tab-pane
              key="5"
              tab="已取消"
            >
            </a-tab-pane>
            <a-tab-pane
              key="6"
              tab="已完成"
            >
            </a-tab-pane>
            <a-tab-pane
              key="7"
              tab="退款/售后"
            >
            </a-tab-pane>
          </a-tabs>
        </div>
      </template>
      <a-row class="filter-wrap ">
        <div class="fillter_flx flex">
          <!-- 复合搜索框 -->
          <ComposeInput
            :selectArr="selectArr"
            placeholderValue="请输入商品名称"
            @getList="getListSearch"
          ></ComposeInput>
        </div>
      </a-row>
      <!-- 筛选项 写死 -->
      <table-filter
        :flitList="flitList"
        :isAllLength="table.pagination.total"
        :isShowAll="isShowAll"
        @transferson="clearfliterSingle"
      ></table-filter>
      <a-table
        class="table-manage flex-1"
        :class=" (flitList[0].value.length || flitList[1].value.length || flitList[2].value.length ||flitList[3].value.length)?'':'pdt-6'"
        @change="tableChanged"
        :pagination="table.pagination"
        :rowKey="record=>record.goods_id"
        :loading="table.loading"
        :columns="config.saleslip.columns"
        :data-source="table.tableData"
        :scroll="{x:1700 , y:getfullHeight((flitList[0].value.length || flitList[1].value.length || flitList[2].value.length ||flitList[3].value.length)? 266 :  212)  }"
        :rowClassName="setRowClass"
        :customRow="customRow"
      >
        <template
          slot="_0"
          slot-scope="action, record"
        >
          <span class="mgr-4 font3">{{ record.goods_name||'--' }}</span>
          <span>{{ record.phone||'135 7776 2883' }}</span>
        </template>
        <!-- 门店 -->
        <span
          slot="shop"
          class="bigdropdown  pointer"
        >
          <a-popover
            :title="null"
            v-model="visibleWay"
            trigger="click"
            placement="bottomRight"
          >
            <template slot="content">
              <a-select
                class="width-min-200"
                optionFilterProp="label"
                showSearch
                allowClear
                v-model="query.goods_brand"
                @change="event=>getbrandsItem('brands',event)"
                placeholder="全部"
              >
                <a-select-option
                  v-for="it in brandsList"
                  :key="it.brand_id"
                  :value="it.brand_id"
                  :label="it.brand_name"
                >
                  {{ it.brand_name }}
                </a-select-option>
              </a-select>

            </template>
            <span style="width:94px;display: inline-block">
              <i
                class="meiye-icon meiye-riqi "
                style="color:#9ea6a4;"
              ></i>
              门店
              <i
                class="meiye-icon meiye-xiala"
                style="width:10px; margin-top: -1px;"
              ></i>
            </span>
          </a-popover>

        </span>
        <template
          slot="_4"
          slot-scope="action, record"
        >
          <span style="margin-left:12px;"> 门店</span>
        </template>
        <!-- 订单时间 -->
        <template slot="ordertimes">
          <div class="cursor-pointer bigdropdown  bigdropdown3 wid-102">
            <a-popover
              :title="null"
              v-model="visibleTime"
              trigger="click"
              placement="bottomRight"
            >
              <template slot="content">
                <!-- 日期选择器 -->
                <a-range-picker
                  @change="setMonth"
                  class="data_picker"
                >
                  <i
                    slot="suffixIcon"
                    class="meiye-icon meiye-rili-moren"
                    :class="{ 't-c-g': isactive }"
                    style="font-size:12px;color:#9ea6a4 ;background: @baseColor41;margin-top: -5px;"
                  ></i>
                </a-range-picker>

              </template>
              <span style="width:94px;display: inline-block">
                <i
                  class="meiye-icon meiye-riqi "
                  style="color:#9ea6a4;"
                ></i>
                订单时间
                <i
                  class="meiye-icon meiye-xiala"
                  style="width:10px; margin-top: -1px;"
                ></i>
              </span>
            </a-popover>
          </div>
        </template>
        <template
          slot="_9"
          slot-scope="action, record"
        >
          <span class="mgl-12"> {{ record.times?'--':'2022/12/21 10:23:32'   }}</span>

        </template>
        <!-- 状态 -->
        <template
          slot="_10"
          slot-scope="action, record"
        >
          <span :class="record.permission_is_show == 1 ? 'baseColor11' : record.goods_type == 2 ? 'errorColor': record.goods_type == 3 ?'font3': record.goods_type == 4 ?'fontcancle':'fontcancle'">
            {{   record.permission_is_show == 1 ? '待发货' : record.goods_type == 2 ? '待支付': record.goods_type == 3 ?"已完成": record.goods_type == 4 ?'已取消':'已退款'}}
          </span>
        </template>
        <!-- 拿货方式 -->
        <template slot="getways">
          <div class="cursor-pointer bigdropdown  bigdropdown3 wid-102">
            <a-dropdown
              :trigger="['click']"
              placement="bottomCenter"
              :overlayStyle='{"width":"102px"}'
              overlayClassName="mydropdown "
            >
              <span style="width: 94px;display: inline-block;">
                <i class="meiye-icon meiye-fenlei"></i>
                拿货方式
                <i
                  class="meiye-icon meiye-xiala"
                  style="width:10px; margin-top: -1px;"
                ></i>
                <span class="borderbox"></span>
              </span>
              <a-menu
                slot="overlay"
                :default-selected-keys="['0']"
                :selected-keys="flitList[0].value"
              >
                <a-menu-item
                  v-for="(v) in filters_categorys"
                  :key="v.text"
                  :value="v.value"
                  @click=";getbrandsItem('catagory',v)"
                  style="text-align:center;margin:0px 5px;padding: 5px 0px;border-radius: 3px;"
                >
                  {{ v.text }}
                </a-menu-item>
              </a-menu>

            </a-dropdown>
          </div>
        </template>
        <template
          slot="_8"
          slot-scope="action, record"
        >
          <span class="mgl-12">{{ record.permission_type==1?'门店自提':record.permission_type==2?"快递":'到店核销' }}</span>
        </template>
        <template
          slot="_7"
          slot-scope="action, record"
        >
          <a-button
            size="small"
            class="mgr-12 smallbtn"
            @click="addRules(record,3)"
          >查看</a-button>
          <!-- 待发货    v-if="record.permission_is_show==1"  -->
          <a-button
            size="small"
            class="mgr-12 smallbtn"
            @click=";sendout.sendoutvisible=true"
          >发货</a-button>
          <!-- v-if="record.permission_is_show==1"  -->
          <a-button
            size="small"
            class="mgr-12 smallbtn"
            style="letter-spacing:0px;"
            @click=";address.addressvisible=true"
          >修改收货地址</a-button>
          <!--  v-if="record.permission_is_show==1 ||record.permission_is_show==3" -->
          <a-button
            size="small"
            class="mgr-12 smallbtn"
            @click=";refund.refundvisible=true"
          >退款</a-button>
          <!--待支付  v-if="record.permission_is_show==2"-->
          <a-button
            size="small"
            class="mgr-12 smallbtn"
            style="letter-spacing:0px;"
            @click=";cancleorder.canclevisible=true"
          >取消订单</a-button>
          <!-- 已完成 -->
          <!-- 已取消 -->
          <!-- 已退款 -->
          <a-popover
            trigger="hover"
            placement="bottomRight"
            :overlayStyle="{'width':'286px'}"
          >
            <template slot="content">
              <div class="font3 mgb-12">订单商品信息</div>
              <div class="shopdetails flex">
                <div class="shopdetails_img"></div>
                <div class="shopdetails_txt">
                  <p class="shopdetails_names">美娜多紧致按摩护理</p>
                  <p class="shopdetails_gg"> <span class="mgr-16">150ml</span> <span class=""> ¥1233.21 X5</span></p>
                </div>
              </div>
              <div class="shopdetails flex">
                <div class="shopdetails_img"></div>
                <div class="shopdetails_txt">
                  <p class="shopdetails_names">美娜多紧致按摩护理</p>
                  <p class="shopdetails_gg"> <span class="mgr-16">150ml</span> <span class=""> ¥1233.21 X5</span></p>
                </div>
              </div>
            </template>
            <a-button
              size="small"
              class="mgr-12 smallbtn"
              style="letter-spacing:0px;"
            >商品明细</a-button>
          </a-popover>
        </template>
      </a-table>
    </div>

    <div
      class=" flex-1"
      v-if="pageStatus=='add'"
    >
      <!-- 此处判断是哪几个组件 2"待支付 "3"待发货 "4"待收货 "5"已取消 "6"已完成 "7"退款/售后"  -->
      <management-add v-if="shoptype==2"></management-add>
      <Delivery v-if="shoptype==3"></Delivery>
      <Deceived v-if="shoptype==4"></Deceived>
      <Canceled v-if="shoptype==5"></Canceled>
      <Completed v-if="shoptype==6"></Completed>
      <Refunded v-if="shoptype==7"></Refunded>
    </div>
    <!-- 退款 -->
    <a-modal
      title="确认退款"
      :visible="refund.refundvisible"
      @cancel=";refund.refundvisible=false;"
      :width="600"
      class="wid_600-modal"
      @ok="makesurerefund"
    >
      <div class=" ">
        <a-form-model
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 18 }"
          labelAlign="left"
        >

          <a-form-model-item label="退款路径">
            <a-radio-group
              name="radioGroup"
              class="nomal_radio radio_nomal1"
              v-model="refund.ways"
            >
              <a-radio :value="1">
                全部退款
              </a-radio>
              <a-radio :value="2">
                部分退款
              </a-radio>
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item
            required
            label="退款金额"
          >
            <a-input
              class="ant-input_1"
              v-model="refund.money"
              :maxLength="10"
              placeholder="请输入退款金额"
            />
          </a-form-model-item>

          <a-form-model-item label="退款说明">
            <a-textarea
              class="ant-input_1"
              style="width: 414px;height: 72px;resize: none;"
              v-model="refund.decs"
              placeholder="请输入退款说明"
              :maxLength="50"
              :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
    <!-- 取消订单 -->
    <a-modal
      title="取消订单"
      :visible="cancleorder.canclevisible"
      @cancel=";cancleorder.canclevisible=false;"
      :width="600"
      class="wid_600-modal"
      @ok="makesurerefund"
    >
      <div class=" ">
        <a-form-model
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 18 }"
          labelAlign="left"
        >
          <a-form-model-item
            label="取消说明"
            style="margin-bottom: 24px;"
          >
            <a-textarea
              class="ant-input_1"
              style="width: 414px;height: 72px;resize: none;"
              v-model="refund.decs"
              placeholder="请输入取消说明"
              :maxLength="50"
              :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
    <!-- 发货 -->
    <a-modal
      title="发货"
      :visible="sendout.sendoutvisible"
      @cancel=";sendout.sendoutvisible=false;"
      :width="600"
      class="wid_600-modal"
      @ok="makesuresendout"
    >
      <div class=" ">
        <a-form-model
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 18 }"
          labelAlign="left"
        >

          <a-form-model-item label="快递公司">
            <a-select
              class="width-173"
              optionFilterProp="label"
              showSearch
              allowClear
              v-model="sendout.company"
              @change="event=>getbrandsItem('brands',event)"
              placeholder="全部快递公司"
            >
              <a-select-option
                v-for="it in sendout.companySelect"
                :key="it.brand_id"
                :value="it.brand_id"
                :label="it.brand_name"
              >
                {{ it.brand_name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item
            required
            label="快递单号"
          >
            <a-input
              class="ant-input_1"
              v-model="refund.tracking"
              :maxLength="10"
              placeholder="请输入快递单号"
            />
          </a-form-model-item>
          <!-- form -->
          <div
            class="addform"
            v-for="(it,idx) in sendout.form"
            :key="idx"
          >
            <div
              class="close-x"
              @click="closex(it)"
            > <i class="meiye-icon meiye-close-bold "></i> </div>
            <a-form-model-item label="快递公司">
              <a-select
                class="width-173"
                optionFilterProp="label"
                showSearch
                allowClear
                v-model="sendout.company"
                @change="event=>getbrandsItem('brands',event)"
                placeholder="全部快递公司"
              >
                <a-select-option
                  v-for="it in sendout.companySelect"
                  :key="it.brand_id"
                  :value="it.brand_id"
                  :label="it.brand_name"
                >
                  {{ it.brand_name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              required
              label="快递单号"
            >
              <a-input
                class="ant-input_1"
                v-model="refund.tracking"
                :maxLength="10"
                placeholder="请输入快递单号"
              />
            </a-form-model-item>
          </div>
          <div class="addtracking">
            <span
              class="addplus"
              @click="addBg"
            >
              <i class="meiye-icon meiye-routeicon-add-copy baseColor11"></i>
            </span>
            <span class="baseColor11 pointer">添加包裹</span>
          </div>

        </a-form-model>
      </div>
    </a-modal>
    <!-- 修改收货地址 -->
    <a-modal
      title="修改收货地址"
      :visible="address.addressvisible"
      @cancel=";address.addressvisible=false;"
      :width="600"
      class="wid_600-modal"
      @ok="makesurerefund"
    >
      <div class="adress_box">
        <a-form-model
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 18 }"
          labelAlign="left"
        >

          <a-form-model-item label="收货人">
            <a-input
              class="ant-input_1"
              v-model="address.peopel"
              :maxLength="10"
              placeholder="请输入收货人"
            />
          </a-form-model-item>
          <a-form-model-item label="联系人">
            <a-input
              class="ant-input_1"
              v-model="address.peopel2"
              :maxLength="10"
              placeholder="请输入联系人"
            />
          </a-form-model-item>
          <a-form-model-item label="收货地址">
            <a-cascader
              :options="address.privince"
              @change="onChangeprivince"
              v-model="address.privincetxt"
              :popupStyle="{'width':'414px'}"
              placeholder="请选择收获地址"
              popupClassName="adressthree"
            />
          </a-form-model-item>

          <a-form-model-item
            style="margin-top:-18px;"
            label="详细地址"
          >
            <a-textarea
              class="ant-input_1"
              style="width: 414px;height: 72px;resize: none;"
              v-model="address.adresstxt"
              placeholder="请输入详细地址"
              :maxLength="50"
              :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>

</template>
<script> 
import storage from 'store'
import moment from 'moment';
import Msg from '@/components/mrj-menu/msg'
import {
  GetmanageGoodslist,
  GetgoodsoptionsList, //门店分类
  TreetypeFinanceList,//财务分类
  TreetypeMallList,//商城分类
  CreateGoods, UpdateGoods, GoodsInfo, GetgoodsbrandsoptionsList, DeLeteGoods, BatchGoods
} from '@/api/commodity'
import config from '../config'
import axios from 'axios'
import UploadImage from '../../commodity/modules/drag'
import ShopEditor from '../../commodity/modules/wangeditor'
import ManagementAdd from './modules/saleslipdetail'//2"待支付7"退款/售后"
import Delivery from './modules/deliverydetail'//3"待发货"
import Deceived from './modules/deceiveddetail'// 4"待收货"
import Canceled from './modules/canceleddetail'// "5"已取消
import Completed from './modules/completeddetail'//6 已完成
import Refunded from './modules/refundeddetail'//7 退款/售后 已退款 

// import ManagementGrounding from '../../commodity/management/management_grounding'
// import ManagementGroundingAll from '../../commodity/management/management_grounding_all'
import ComposeInput from '@/components/compose_input'
import mockData1 from '../../commodity/modules/mockdata'
import difference from 'lodash/difference';
import districtData from '@/utils/city'

// import { TableFilter } from 'huanlong-components'
// import  TableFilters  from '@/components/intimate/tableFilter'

const leftTableColumns = [
  {
    dataIndex: 'title',
    title: 'title',
  },
  {
    dataIndex: 'component',
    title: 'component',
  },
];
const rightTableColumns = [
  {
    dataIndex: 'title',
    title: 'title',
  },
  {
    dataIndex: 'component',
    title: 'component',
  },
];
const treeDatas = [
  { key: '0-0', title: '0-0' },
  {
    key: '0-1',
    title: '0-1',
    children: [
      { key: '0-1-0', title: '0-1-0' },
      { key: '0-1-1', title: '0-1-1' },
    ],
  },
  { key: '0-2', title: '0-3' },
]
const transferDataSource = [];
function flatten(list = []) {
  list.forEach(item => {
    transferDataSource.push(
      // {
      // title: item.title,
      // key: item.id.toString(),
      // value: item.path ? item.path : 'xxx'
      item
      // }
    );
    flatten(item.children);
    console.log(transferDataSource, 'typesModel.dataSource');
  });
}
flatten(JSON.parse(JSON.stringify(treeDatas)));
function isChecked(selectedKeys, eventKey) {
  return selectedKeys.indexOf(eventKey) !== -1;
}

function handleTreeData(data, targetKeys = []) {
  data.forEach(item => {
    item['disabled'] = targetKeys.includes(item.key);
    if (item.children) {
      handleTreeData(item.children, targetKeys);
    }
  });
  return data;
}

export default {
  components: {
    UploadImage, ShopEditor, ComposeInput,
    ManagementAdd,
    Delivery,
    Deceived,
    Canceled,
    Completed,
    Refunded,
    // , TableFilters
  },
  data() {
    return {
      pageStatus: 'list',//groundingAll  add    
      config,
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      headers: {
        authorization: storage.get('token'),
        "Content-Type": "multipart/form-data",
      },
      selectArr: [
        { name: '商品名称', id: '1', key: 1 },
        { name: '商品码', id: '2', key: 2 },
      ],
      selectArr2: [
        { name: '财务分类', id: '1', key: 1 },
        { name: '门店分类', id: '2', key: 2 },
        { name: '商城分类', id: '3', key: 3 },
      ],
      selectArr3: [
        { name: '机构简称', id: '1', key: 1 },
        { name: '机构名称', id: '2', key: 2 },
        { name: '机构代码', id: '3', key: 3 },
      ],
      statusvisible: false,
      typestyles: {
        width: '334px',
        height: "32px"
      },
      typestyles2: {
        width: '506px',
        height: "32px"
      },
      isactive: false,
      activeStatus1: '1',//商品名称 商品码
      activeStatus2: '1',//批处理
      filters_categorys: [//商品分类
        { text: '全部', value: '0', key: 0 },
        { text: '门店自提', value: '1', key: 1 },
        { text: '快递', value: '2', key: 2 },
        { text: '到店核销', value: '3', key: 3 }
      ],
      activeStatus_catagory: [],
      filters_status: [
        { text: '全部', value: '0', key: 0 },
        { text: '在售', value: '1', key: 1 },
        { text: '停售', value: '2', key: 2 },
      ],
      activeStatus_status: [],
      activeStatus_brand: [],
      // 分类 树形选择框
      filters_types: [

      ],
      activeStatus_types: [],
      ugro_id: '',
      query: {
        goods_name: undefined,//商品名称
        goods_bn: undefined,//商品码
        goods_type: [1, 2, 3, 4], //1客装 2院装 不传全部
        is_show: undefined, //1在售 0停售 不传为全部
        goods_brand: undefined, //品牌id 不传为全部
        goods_category: undefined, //门店分类 不传为全部
        store_category_id: undefined,// 商城分类
        finance_category_id: undefined,//财务分类
        start_data: undefined,//开始时间
        end_data: undefined,//结束时间
        limit: 10,
        page: 1,
      },
      table: {
        tableData: [],
        selectedRowKeys: [],
        selectedRows: [],
        loading: false,
        pagination: {
          current: 1,
          total: 0,
          pageSize: 10, //每页中显示10条数据
          showSizeChanger: true,
          showQuickJumper: true,
        },
      },

      pulsshow: true,
      permissionInfo: {
        visible: false,
        text: '提交',
        title: '',
        type: 1,//1新增   3编辑
        pic_url_loading: false,
        isDisabled: false,
        info: {
          permission_id_list: [],
          goods_type: [1, 2, 3, 4],//商品类型
          goods_category: undefined,//商品分类
          goods_brand: undefined,//商品品牌
          goods_bn: undefined,
          goods_id: undefined,
          goods_name: undefined,
          goods_supplier: undefined,
          goods_unit: undefined,// 单位
          is_show: 1,// 商品状态
          is_del: 0,//是否删除
          goods_image: undefined,
          goods_slider: undefined,//轮播图
          goods_attr: [],//商品规格
          goods_attr_val: [],//商品规格值 
          goods_content: '<h1>q<strong>wqeddd</strong><span style=\"background-color: rgb(196, 29, 127);\">dxxxxx</span></h1><p>撒打 撒啊大</p>',//商品描述
          goods_desc: undefined,//商品简介
        }
      },
      permission_id_list: [],
      permission_id_list2: [],
      permission_id_list3: [],
      brandsList: [],
      treeData: [],
      treeData1: [],
      treeData2: [],
      treeData3: [],
      selectedKeys: [],
      selectedKeys2: [],
      selectedKeys3: [],
      expandedKeys: [],
      autoExpandParent: false,
      backupsExpandedKeys: [],
      titleList: [],//分类筛选名字 //财务  
      searchValue: '',
      selectreeObj: {
        children: 'children',
        title: 'category_name',
        key: 'category_id',
        value: 'category_id'
      },
      selectreeObj2: {
        children: 'children',
        title: 'category_name',
        key: 'finance_category_id',
        value: 'finance_category_id'
      },
      selectreeObj3: {
        children: 'children',
        title: 'category_name',
        key: 'store_category_id',
        value: 'store_category_id'
      },
      addorupdateSpecFlag: 'add',//是修改规格还是编辑 编辑的时候只能添加规格值



      opentimes: {
        visible: false,
        data: {
          goods_id: '',
          goods_shou_type: 1,//1 长期 （默认） 2截止
          goods_shou_end_time: ''
        },
      },
      exportsobj: { //导出
        visible: false,
      },
      importsobj: {//导入
        visible: false,
      },
      visibleTime: false,//订单时间
      visibleWay: false,//拿货方式
      // 
      flitList: [
        { key: '类别', value: [], paramas: 'goods_type' },
        { key: '分类', value: [], paramas: 'goods_category' },
        { key: '状态', value: [], paramas: 'is_show' },
        { key: '品牌', value: [], paramas: 'goods_brand' },
        { key: '上架时间', value: [], paramas: 'data_time' },
        { key: '下架时间', value: [], paramas: 'data_time' },
      ],
      visiblePlan: false,
      isShowAll: true,
      isAllLength: '',
      CheckSureObj: {
        checkedKeys: undefined,
        info: undefined,
        tabs: 1,//财务1 商城3 门店2
      },
      refund: {//退款弹框
        refundvisible: false,
        ways: 1, // 退款路径 全部1 部分2
        money: undefined,//退款金额
        decs: undefined,// 退款说明
      },
      cancleorder: { //取消订单
        canclevisible: false,

      },
      sendout: {//发货
        sendoutvisible: false,
        company: undefined, // 快递公司
        companySelect: [],//快递公司列表
        tracking: undefined,// 快递单号
        form: [//添加包裹列表
          // { tracking: undefined, id: 0, company_id: 1 },
        ],
      },
      address: {
        addressvisible: false,
        peopel: undefined, // 收货人
        peopel2: undefined, // 联系人
        adress: undefined, // 收货地址
        adresstxt: undefined,//详细地址
        privince: districtData || [],//省市区联动
        privincetxt: undefined,
      },
      shoptype: '7',//2"待支付"3"待发货"4"待收货"5"已取消"6"已完成"7"退款/售后"

    }
  },
  computed: {
    treeDatas() {
      return handleTreeData(treeDatas, this.typesModel.targetKeys);
    },
  },
  async created() {
    if (this.$route.query.type && this.$route.query.type == 1) {// 添加
      this.pageStatus = 'add'
      let timers = setTimeout(() => {
        Msg.$emit('changethirdaryShow', false)
        clearTimeout(timers)
        timers = ''
      },)

    } else if (this.$route.query.type && this.$route.query.type == 3) { //编辑
      this.pageStatus = 'add'
      let timers = setTimeout(() => {
        Msg.$emit('changethirdaryShow', false)
        clearTimeout(timers)
        timers = ''
      },)
    } else if (this.$route.query.type && this.$route.query.type == 2) { //上架
      this.pageStatus = 'grounding'
      let timers = setTimeout(() => {
        Msg.$emit('changethirdaryShow', false)
        clearTimeout(timers)
        timers = ''
      },)
    } else if (this.$route.query.type && this.$route.query.type == 4) { //批量上架
      this.pageStatus = 'groundingAll'
      let timers = setTimeout(() => {
        Msg.$emit('changethirdaryShow', false)
        clearTimeout(timers)
        timers = ''
      },)
    } else { //列表
      this.pageStatus = 'list'
      let timers = setTimeout(() => {
        Msg.$emit('changethirdaryShow', true)
        clearTimeout(timers)
        timers = ''
      },)
    }

    if (!this.$route.query.type) {

      console.log(this.$route.query);
      this.getBrands()
      this.getList()

      this.getCompanyoptionList()
      this.getCompanyoptionList2()
      this.getCompanyoptionList3()
    }
  },
  mounted() {

  },
  methods: {
    flatten(list = []) {
      let _this = this
      if (list) {
        list.forEach(item => {
          _this.limitsModel.mockData.push({
            key: item.id.toString(),
            title: item.title ? item.title : 'we' + item.id,
            component: item.component ? item.component : 'component',
            pid: item.pid,
            disabled: false,
            description: 'xxx'
          });
          _this.flatten(item.children);
        });
      }
    },
    handleMenuClick({ key }) {
      // insale   outsale groundingAll export
      if (key == 'insale' || key == 'outsale') {
        let data = {
          "goods_ids": this.table.selectedRowKeys,
          "save_data": {
            "is_show": key == 'insale' ? 1 : 0
          }
        }
        BatchGoods(data).then(res => {
          if (res.error_code == 0) {
            this.$message.success('操作成功')
            this.query.page = 1
            this.getList()
          }
        })
      } else if (key == 'groundingAll') {//批量上架
        // 传值this.table.selectedRows  数组   跳转页面  

        console.log(this.table.selectedRowKeys.join());
        console.log(this.$store.state.groudingList);
        // this.$store.commit("setGroudingList", this.table.selectedRows);
        storage.set('groudingList', this.table.selectedRows);
        this.pageStatus = 'groundingAll'
        this.$router.replace({ path: '/shop/management/managementlist', query: { type: 4, ids: this.table.selectedRowKeys.join() } })

        // this.$router.replace({ path: '/shop/management/managementlist', query: { type: 2 } })
      }

    },

    handleMenuClickSearch(key) {
      if (key.id == 1) { //1财务  
        this.treeData = this.treeData1
        this.CheckSureObj.tabs = 1
        // this.permission_id_list = []
        // this.selectreeObj = this.selectreeObj

        this.permissionInfo.info.permission_id_list = this.permission_id_list

      }
      if (key.id == 2) { // 2 门店 
        this.treeData = this.treeData2
        this.CheckSureObj.tabs = 2
        // this.selectreeObj = this.selectreeObj2
        // this.permissionInfo.info.permission_id_list = []
        this.permissionInfo.info.permission_id_list = this.permission_id_list2
      }
      if (key.id == 3) { // 3 商城
        this.treeData = this.treeData3
        this.CheckSureObj.tabs = 3
        // this.selectreeObj = this.selectreeObj3
        // this.permissionInfo.info.permission_id_list = []
        this.permissionInfo.info.permission_id_list = this.permission_id_list3

      }

    },
    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf('day');
    },
    // table 多选
    onSelectChange(selectedRowKeys, selectedRows) {
      console.log(selectedRowKeys, selectedRows);
      this.table.selectedRowKeys = selectedRowKeys
      this.table.selectedRows = selectedRows
      // if (!selectedRows.length) {
      //   var isshow = false
      //   if (document.querySelector('.table-manage .ant-table-header .ant-checkbox-inner')) { document.querySelector('.table-manage .ant-table-header .ant-checkbox-inner').style.display = "none" }
      //   document.querySelector('.btn-padding').style.display = "none"
      // } else {
      //   isshow = true
      //   document.querySelector('.btn-padding').style.display = "inline-block"
      //   document.querySelector('.table-manage .ant-table-header .ant-checkbox-inner').style.display = "inline-block"
      // }
      // let times = setTimeout(() => {
      //   if (isshow) {

      //     // console.log(document.querySelectorAll('.ant-table-row-selected'));
      //     document.querySelectorAll('.ant-table-row-selected').forEach(el => {
      //       el.children[0].children[0].style.display = "inline-block"
      //       el.children[0].children[0].children[0].style.display = "inline-block"
      //       el.children[0].children[0].children[0].children[0].style.display = "inline-block"
      //       clearTimeout(times)
      //       times = ''
      //     })
      //   } else {
      //     document.querySelectorAll('.ant-table-body .ant-table-selection-column').forEach(el => {
      //       el.children[0].children[0].style.display = "none"
      //       clearTimeout(times)
      //       times = ''
      //     })
      //   }

      // });


    },
    getCheckboxPropsfn(record) {
      return {
        props: {
          disabled: record.status_text == "已结束" // 禁选项
        }
      };
    },
    setRowClass(record) {
      // console.log(record, 'record');
      if (record.card_status == 0) {
        // return "rowClass";
        return "failRowClass";
      } if (record.card_status == 1) {
        return "successRowClass";
      } else {
        return "successRowClass";
      }

    },
    customRow(record, index) {
      return {
        on: {
          click: event => {
            // document.querySelector('.ant-table-header .ant-checkbox-inner').style.display = "inline-block"
          },
          mouseenter: event => {
            // console.log(document.querySelector('.ant-table-header .ant-checkbox-inner'), "vxvxfvfxv");
            // console.log(event.target.children[0].children[0]);
            // console.log(event.target.children[0].children[0].children[0]);
            // event.target.children[0].children[0].style.display = "inline-block"
            // event.target.children[0].children[0].children[0].style.display = "inline-block"
            // event.target.children[0].children[0].children[0].children[0].style.display = "inline-block"
            // document.querySelector('.ant-table-header .ant-checkbox-inner').style.display = "inline-block"
            // document.querySelector('.ant-table-header .ant-table-selection-column').style.display = "inline-block"
          },
          mouseleave: (event) => {
            // console.log(event.target.className.indexOf('ant-table-row-selected'));
            // if (event.target.className.indexOf('ant-table-row-selected') < 0) {
            //   event.target.children[0].children[0].style.display = "none";
            //   // document.querySelector('.ant-table-header .ant-checkbox-inner').style.display = "none"
            //   // document.querySelector('.ant-table-header .ant-table-selection-column').style.display = "none"

            // }

          }

        },
      }

    },
    getfullHeight(val) {
      console.log(document.body.offsetHeight - val);
      return document.body.offsetHeight - val - 159 + 16
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.permissionInfo.info.pic_url_loading = true
        return;
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        const { file_url } = info.file.response.data
        this.permissionInfo.info.permission_icon = file_url
        this.permissionInfo.info.pic_url_loading = false
      }
    },
    handleChangedrag(info) {
      const status = info.file.status;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        this.$message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },
    // 删除 
    handleBatch(key, record, title = "删除") {
      let self = this
      let deleteRules = DeLeteGoods
      const urlArr = [
        { name: deleteRules, key: 'delete', okType: 'primary', msg: '您将删除产品【' + record[0].goods_name + '】，是否确认删除？' },
      ]
      const activeObj = urlArr.filter(it => it.key == key)[0]
      self.$confirm({
        icon: 'exclamation-circle',
        closable: true,
        title,
        content: activeObj.msg,
        okText: '确定',
        closable: true,
        okType: activeObj.okType,
        cancelText: '取消',
        onOk() {
          const data = {
            goods_id: record[0].goods_id,
          };
          (activeObj.name)(data).then(res => {
            if (res.error_code == 0) {
              self.$message.success('操作成功')
              self.query.page = 1
              self.getList()
            }
          }).finally(r => {
          })
        },
        onCancel() {
        },
      });
    },
    // 商品品牌
    getBrands() {
      GetgoodsbrandsoptionsList().then(res => {
        console.log(res, "getBrands");
        if (res.error_code == 0) {
          this.brandsList = res.data
          this.brandsList.unshift({
            brand_icon: "meiye-routeicon-chart-pie",
            brand_id: 99999,
            brand_name: "全部",
            brand_sort: 1,
            brand_status: 1,
            created_at: "2023-04-20 16:30:25",
          })
        }
      })
    },
    // 
    getListSearch(e) {
      // console.log(e);
      this.query.page = 1
      if (e.activeStatus1.name == "商品名称") {
        this.query.goods_name = e.value
        this.query.goods_bn = undefined
        this.getList()
      } else {
        this.query.goods_name = undefined
        this.query.goods_bn = e.value
        this.getList()
      }
    },
    getList() {

      let self = this
      self.table.loading = true
      const params = {
        filter: {
          goods_name: self.query.goods_name,//商品名称
          goods_bn: self.query.goods_bn,//商品码
          goods_type: self.query.goods_type, //1客装 2院装 不传全部
          is_show: self.query.is_show, //1在售 0停售 不传为全部
          goods_brand: self.query.goods_brand == 99999 ? undefined : self.query.goods_brand, //品牌id 不传为全部
          goods_category: self.query.goods_category, //门店分类 不传为全部
          store_category_id: self.query.store_category_id,// 商城分类
          finance_category_id: self.query.finance_category_id,//财务分类
          start_data: self.query.start_data,
          end_data: self.query.end_data,
        },
        page: self.query.page,
        limit: self.query.limit
      }

      return GetmanageGoodslist(params).then(res => {
        console.log(res.data, "data");
        const { list = [], total = 0 } = res.data
        self.table.tableData = list
        self.table.pagination = Object.assign({}, { pageSize: this.query.limit, current: this.query.page }, {
          total: total
        })
        console.log(self.table.tableData, "///self.table.tableData");
        self.onSelectChange([], [])
      }).finally(r => {
        self.table.loading = false
      })
    },
    // 商品树形列表
    getCompanyoptionList(e) {
      GetgoodsoptionsList().then(res => {
        this.treeRecursion(res.data, 'store_category_id')
        this.treeData3 = res.data
        console.log(this.treeData3, '>>>this.coptionsList3');
        if (!e) {
          // this.query.company_parent = res.data[0].company_id
          // this.permissionInfo.info.company_parent = res.data[0].company_id
          this.getList()
        }

      })
    },
    //  门店分类 树形列表
    getCompanyoptionList(e) {
      GetgoodsoptionsList().then(res => {
        this.treeRecursion(res.data, 'category_id')
        this.treeData2 = res.data
        console.log(this.treeData2, '>>>this.coptionsList');
        this.treeRecursion2(this.treeData2, 'disabled')

      })
    },
    //  财务分类 列表
    getCompanyoptionList2(e) {
      TreetypeFinanceList().then(res => {
        this.treeRecursion(res.data, 'finance_category_id')
        this.treeData1 = res.data
        this.treeData = res.data
        console.log(this.treeData, '>>>this.treeData2');
      })
    },
    //  商城分类 树形列表
    getCompanyoptionList3(e) {
      TreetypeMallList().then(res => {
        this.treeRecursion(res.data, 'store_category_id');
        this.treeData3 = res.data
        this.treeRecursion2(this.treeData3, 'disabled')
        console.log(this.treeData, '>>>this.coptionsList');


      })
    },

    // 递归  disabled   disableCheckbox
    treeRecursion2(data, i) {
      data.forEach(el => {
        // 把数字类型转为字符串  
        if (el.category_parent_path.includes(',')) {
          el[i] = false
        } else {
          el[i] = true
        }
        if (el.children && el.children.length && !el.category_parent_path.includes(',')) this.treeRecursion2(el.children, 'disableCheckbox');
      })
    },

    // 递归改变id key number
    treeRecursion(data, str) {
      data.forEach(el => {
        // 把数字类型转为字符串 
        if (typeof (el[str]) == 'number') {
          el[str] = el[str].toString()
        }
        if (el.children && el.children.length) this.treeRecursion(el.children);
      })
    },




    tableChanged(pagination, filters, sorter, { currentDataSource }) {
      const { current, pageSize } = pagination
      const { field, order } = sorter
      this.query = Object.assign(this.query, { limit: Number(pageSize), page: Number(current) })
      // self.query.page = 1
      this.getList()
    },

    changeStatus($event, e) {
      let obj = {}
      this.table.tableData.forEach(el => {
        if (e.role_id == el.role_id) {
          el.role_status = $event ? 1 : 0
          obj = el
        }
      })
      this.permissionInfo.type = 3 //编辑
      this.permissionInfo.info = {
        role_id: obj.role_id,
        // system_id: obj.system_id,
        role_name: obj.permission_name,
        permission_id_list: [],
        role_status: obj.role_status
      }
      this.okclickmod()
    },
    onChangePermissiontype(e) {
      console.log('radio checked', e.target.value, this.permissionInfo.info.permission_type);
    },
    // 提交按钮
    okclickmod(e) {
      function fn(beforeData, addorupdateSpecFlag) {
        var afterData = []
        beforeData.forEach(item => {
          let flag
          if (addorupdateSpecFlag == 'add') {
            flag = afterData.find(item1 => item1.product_id === item.product_id)
          } else {
            flag = afterData.find(item1 => item1.product_id_add === item.product_id_add)
          }

          if (!flag) {
            if (addorupdateSpecFlag == 'add') {
              afterData.push({
                product_id: item.product_id,
                product_attr: [item]
              })
            } else {
              afterData.push({
                product_id_add: item.product_id_add,
                product_attr: [item]
              })
            }

          } else {
            flag.product_attr.push(item)
          }
        })
        console.log(afterData, 'afterDataafterData');
        return afterData

      }
      function chkstrlen(str) {
        var strlen = false;
        for (var i = 0; i < str.length; i++) {
          if (str.charCodeAt(i) > 255) //如果是汉字， 
            strlen = true;
        }
        return strlen;
      }
      // 这里要搞事情 goods_specs.isSingle  生成多规格商品列表 goodSpecstableData
      if (this.goods_specs.isSingle !== 0) {
        this.permissionInfo.info.goods_attr_val = this.goodSpecstableData
        let arry = []
        this.permissionInfo.info.goods_attr_val


        this.permissionInfo.info.goods_attr_val.forEach(el => {
          this.permissionInfo.info.goods_attr.forEach((m, n) => {
            let obj = {}
            if (el.hasOwnProperty(m.attr_name)) {
              //   
              obj[m.attr_name] = el[m.attr_name]
              obj.product_id = el.product_id
              obj.product_id_add = el.product_id_add
              arry.push(obj)
            }
          })
        })

        console.log(arry, "mainarr....");
        // arry=[ {"颜色":"白色"}， {"颜色":"黑色"}，{"尺寸":"m"}，{"尺寸":"l"}  ]
        let mainarr = fn(arry, this.addorupdateSpecFlag)
        mainarr.forEach(m => {
          let s = JSON.stringify(m.product_attr)
          m.product_attr = JSON.parse(s.replace(/},{/g, ","))
        })
        //   
        console.log(mainarr, "mainarr....");
        this.permissionInfo.info.goods_attr_val.forEach(el => {
          mainarr.forEach((m, n) => {
            if (el.product_id == m.product_id || el.product_id_add == m.product_id_add) {
              el.product_attr = m.product_attr[0]
              for (let item in el.product_attr) {
                let names = `"${item}"`
                el.product_attr[names] = el.product_attr[item]
                delete el.product_attr[item]
              }
            }
          })
        })

        console.log(this.permissionInfo.info.goods_attr_val, " this.permissionInfo.info.goods_attr_val");
        this.permissionInfo.info.goods_attr_val.forEach((el, idx) => {
          delete el.product_id
          delete el.product_id_add
          delete el.product_attr['"product_id"']
          delete el.product_attr['"product_id_add"']
          delete el.product_attr['"created_at"']
          delete el.product_attr['"updated_at"']
          delete el.created_at
          delete el.updated_at
          for (var pl in el) { //数组对象遍历
            console.log(pl); //获取key
            console.log(el[pl]) //获取key的值
            let flag = chkstrlen(pl) //判断是否中文字符
            if (flag) {
              delete el[pl]
            }
          }
        })
        //   
        console.log(this.permissionInfo.info.goods_attr_val);

      } else {
        this.permissionInfo.info.goods_attr = []
        delete this.goodSpecsSingletableData[0].product_id
        delete this.goodSpecsSingletableData[0].product_id_add
        this.permissionInfo.info.goods_attr_val = this.goodSpecsSingletableData
      }
      delete this.permissionInfo.info.pic_url_loading

      // 区分新增 修改接口
      if (this.permissionInfo.type == 3) {
        delete this.permissionInfo.info.created_at
        delete this.permissionInfo.info.updated_at
        let data = {
          ...this.permissionInfo.info
        }

        UpdateGoods(data).then(res => {
          if (res.error_code == 0) {
            this.$message.success(res.data.message)
            this.permissionInfo.visible = false
            this.getList()
          }
        })
      } else {
        //新增 
        let data = {
          ...this.permissionInfo.info
        }
        delete data.product_id
        delete data.product_id_add
        CreateGoods(data).then(res => {
          if (res.error_code == 0) {
            this.$message.success(res.data.message)
            this.permissionInfo.visible = false
            this.getList()
          }
        }).catch(err => {
          console.log(err);
        })
      }
    },
    async addRules(e, i) {
      this.permissionInfo.isDisabled = false
      this.permissionInfo.info = {
        goods_id: undefined,
        goods_name: '',
        goods_bn: '',
        goods_unit: undefined,
        is_give: 0,//0不允许 
        is_show: 1,
        goods_type_hz: 0,//合作项目 需要联动 goods_type   
        goods_attr: undefined,
        goods_attr_val: [{ product_image: '', product_price: '', product_bn: "" }],
        goods_desc: undefined,
        goods_image: undefined,
        goods_slider: undefined,
        goods_type: '1',
        goods_supplier: 1,
        goods_brand: undefined,
        finance_category_id: undefined,
        store_category_id: undefined,
        goods_category: undefined,
        goods_content: undefined,
        goods_shou_type: 1,
        goods_shou_end_time: null
      }
      // await this.getCompanyoptionList(true)
      console.log(this.treeData);
      this.permissionInfo.visible = true

      if (i == 3) {//编辑
        this.pageStatus = 'add'
        this.permissionInfo.isDisabled = true
        this.permissionInfo.title = '编辑商品'
        this.addorupdateSpecFlag = 'update'
        this.permissionInfo.info.goods_id = e.goods_id
        this.permissionInfo.type = 3
        // this.openDatails()
        console.log(this.permissionInfo.info);
        this.$router.push({ path: '/mall/mallorder/saleslip', query: { type: i, id: e.goods_id } })
      } else if (i == 1) {
        this.pageStatus = 'add'
        this.permissionInfo.title = '添加商品'
        this.addorupdateSpecFlag = 'add'
        this.permissionInfo.type = 1
        delete this.permissionInfo.info.goods_id
        this.$router.replace({ path: '/mall/mallorder/saleslip', query: { type: i } })
        console.log(this.permissionInfo);

      } else if (i == 2) {
        this.permissionInfo.title = '上架'
        this.pageStatu = 'grounding'
        this.$router.replace({ path: '/shop/management/managementlist', query: { type: i, id: e.goods_id, bn: e.goods_bn, name: e.goods_name } })

      }
      Msg.$emit('changethirdaryShow', false)
    },
    // 打开详情
    openDatails() {
      GoodsInfo({ goods_id: this.permissionInfo.info.goods_id }).then(res => {
        this.permissionInfo.info = Object.assign(this.permissionInfo.info, res.data)
        this.permissionInfo.info.goods_type = this.permissionInfo.info.goods_type + ''
        this.permissionInfo.info.goods_brand = this.permissionInfo.info.goods_brand + ''
        this.permissionInfo.info.goods_supplier = this.permissionInfo.info.goods_supplier + ''
        this.permissionInfo.info.goods_slider = JSON.parse(this.permissionInfo.info.goods_slider)
        this.permissionInfo.info.goods_attr_val.forEach((el, idx) => {
          el.product_id_add = idx
        })
        let fileList = []
        // 回显轮播图
        this.permissionInfo.info.goods_slider.forEach((val, key) => {
          fileList.push({
            uid: '-' + key,
            // name: '' + val.name, // 文件名
            status: 'done',
            url: '' + val
          })
        })
        this.$refs.uploadCom.fileList = fileList
        // 单规格 or 多规格
        if (this.permissionInfo.info.goods_attr.length) {//多规格
          this.goods_specs.isSingle = 2
          this.goods_specs.new_goods_specs_list = []
          this.goods_specs.goods_specs_keys_list = []
          //  这里多规格 回显设置规格的值列表
          this.permissionInfo.info.goods_attr.forEach((el, idx) => {
            let obj = {
              id: "p" + idx,
              attr_name: el.attr_name,
              attr_values: el.attr_values
            }
            this.goods_specs.new_goods_specs_list.push(obj)
          })
          // 还需要回显规格商品表
          this.goodSpecstableData = []

          // 处理 goods_specs_clomns
          this.goods_specs_clomns = []
          let arri1 = []
          this.permissionInfo.info.goods_attr.forEach((el, idx) => {
            let obj = {
              title: el.attr_name,
              dataIndex: el.attr_name,
              key: el.attr_name,
              ellipsis: true,
              scopedSlots: { customRender: el.attr_name },
            }
            let object1 = {
              attr_name: el.attr_name,
              attr_values: el.attr_values,
              product_id: el.product_id
            }
            this.goods_specs_clomns.push(obj)
            arri1.push(object1)
          })
          this.permissionInfo.info.goods_attr = arri1
          this.goods_specs_clomns = [... this.goods_specs_clomns, ...this.goods_specs__sigle_clomns]
          console.log(this.goods_specs_clomns, "...this.goods_specs_clomns");
          let newResult = arri1.reduce((a, b, c) => {
            let res = []
            a.map(x => {
              b.attr_values.map(y => {
                res.push({ ...x, [b.attr_name]: y })
              })
            })
            return res
          }, [{}])
          //   回显 多规格列表 形式
          newResult.forEach((el, idx) => {
            let obj = {
              product_id: this.permissionInfo.info.goods_attr_val[idx].product_id,
              product_bn: this.permissionInfo.info.goods_attr_val[idx].product_bn,
              product_attr: this.permissionInfo.info.goods_attr_val[idx].product_attr,
              product_is_def: this.permissionInfo.info.goods_attr_val[idx].product_is_def,
              product_image: this.permissionInfo.info.goods_attr_val[idx].product_image,
              product_price: this.permissionInfo.info.goods_attr_val[idx].product_price,
              product_cost_price: this.permissionInfo.info.goods_attr_val[idx].product_cost_price,
              product_market_price: this.permissionInfo.info.goods_attr_val[idx].product_market_price,
              product_stock: this.permissionInfo.info.goods_attr_val[idx].product_stock
            }
            el = Object.assign(el, obj)
          })
          this.goodSpecstableData = newResult
          // console.log(this.goods_specs.new_goods_specs_list, "goods_specs.new_goods_specs_list");

        } else {//单规格
          this.goods_specs.isSingle = 1
          this.goodSpecsSingletableData = this.permissionInfo.info.goods_attr_val
        }

      })
    },
    // 递归改变id key number
    treeRecursion(data) {
      data.forEach(el => {
        // 把数字类型转为字符串 
        if (typeof (el.company_id) == 'number') {
          el.company_id = el.company_id.toString()
        }
        if (el.children && el.children.length) this.treeRecursion(el.children);
      })
    },
    // 自定义展开关闭图标
    customExpandIcon(props) {
      console.log(props)
      // if(props.record.children.length > 0){
      if (props.expanded) {
        return <a style={{ color: 'black', marginRight: 8 }} onClick={e => {
          props.onExpand(props.record, e);
        }}><a-button type="link">关闭详情</a-button></a>
      } else {
        return <a style={{ color: 'black', marginRight: 8 }} onClick={e => {
          props.onExpand(props.record, e);
        }}><a-button type="link">查看详情</a-button></a>
      }
      // }else{
      //     return <span style={{marginRight:8 }}></span>
      // }
    },

    beforeUpload(file, fileList) {
      //   
      var self = this
      return new Promise((resolve, reject) => {
        const isLt1M = file.size / 1024 / 1024 > 1
        if (isLt1M) {
          self.$message.error('上传文件大于1MB!')
          reject(false)
        }
        if (fileList.length > 1) {
          self.$message.error('只能上传一张图片！')
          reject(false)
        }
        var fileNames = file.name.split('.')
        var fileType = fileNames[fileNames.length - 1].toLocaleLowerCase()
        var extList = ['jpg', 'png', 'jpeg']
        if (!extList.find((item) => item == fileType)) {
          self.$message.error('只能上传.jpg/.png/.jpeg类型的图片！')
          reject(false)
        }
        resolve(true)
      }).finally(() => {
      })
    },
    // 商品头图
    async uploadFiles(info) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.permissionInfo.info.goods_image = res.data.url
      } else {
        this.$message.error(res.error.message)
      }
    },
    // 规格图
    async uploadspecFiles(info, record, flag) {
      // console.log(info)
      // console.log(record); 
      // console.log(this.goodSpecstableData);
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        if (flag) {
          this.goodSpecsSingletableData[0].product_image = res.data.url
        } else {
          this.goodSpecstableData.forEach(el => {
            if (el.product_id == record.product_id || el.product_id_add == record.product_id_add) {
              el.product_image = res.data.url
            }
          })
        }


        // this.permissionInfo.info.goods_image = res.data.url
        console.log(this.goodSpecstableData);
      } else {
        this.$message.error(res.error.message)
      }


    },
    uploadFilesToServer(uploadApiUrl, fileName, files) {
      let formData = new FormData();
      formData.append(fileName, files);
      //添加请求头
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      //配置头
      const request = axios.create({
        headers: headers,
      });
      //开始上传
      return request
        .post(uploadApiUrl, formData)
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    async uploadFilesDrag(info) {

      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.permissionInfo.info.goods_image = res.data.url
      } else {
        this.$message.error(res.error.message)
      }
    },
    callback(key) {
      console.log(key);
    },

    getDelImageId(val) {
      if (this.permissionInfo.info.goods_slider && this.permissionInfo.info.goods_slider.length > 0) {

      } else {
        this.permissionInfo.info.goods_slider = []
      }
      this.permissionInfo.info.goods_slider.push(val)
    },
    getImageshow(val) {
      this.pulsshow = val
    },
    delDelimageId(index) {
      this.permissionInfo.info.goods_slider.forEach((val, key) => {
        if (index === key) {
          this.permissionInfo.info.goods_slider.splice(key, 1)
        }
      })
    },
    // 获取到重新排序后的图片
    handleDraggable(e) {
      const imgDrag = []
      for (var i = 0; i < e.length; i++) {
        // var a = e[i].url.split('/')
        imgDrag.push(e[i].url)
      }
      this.permissionInfo.info.goods_slider = imgDrag
    },
    //往表格里面加规格列表 
    hideModal() {
      var arr = []
      var arr_goods_attr = []
      this.goods_specs.new_goods_specs_list
      this.goods_specs.goods_specs_list
      //   
      arr = [...this.goods_specs.new_goods_specs_list, ...this.goods_specs.goods_specs_keys_list]

      // todo 以后这里需要把规格列表传给后台 成功之后 1表单头，2表单体数据

      let arri1 = []
      // coluns
      let columns = []
      let specs_clomns = [
        {
          title: '规格图',
          dataIndex: 'product_image',
          key: 'product_image',
          ellipsis: true,
          scopedSlots: { customRender: 'product_image' },
        },
        {
          title: '*标准单价',
          dataIndex: 'product_price',
          key: 'product_price',
          ellipsis: true,
          scopedSlots: { customRender: 'product_price' },
        },
        {
          title: '货品码',
          dataIndex: 'product_bn',
          key: 'product_bn',
          ellipsis: true,
          scopedSlots: { customRender: 'product_bn' },
        },
      ]
      arr.forEach((el, index) => {
        let object1 = {
          attr_name: el.attr_name,
          attr_values: el.attr_values,
        }
        let object2 = {
          title: el.attr_name,
          dataIndex: el.attr_name,
          key: el.attr_name,
          ellipsis: true,
          scopedSlots: { customRender: el.attr_name },
        }
        arri1.push(object1)
        columns.push(object2)
      })
      this.permissionInfo.info.goods_attr = arri1 //规格
      // 动态生成表头
      this.goods_specs_clomns = [...columns, ...specs_clomns]

      let newResult2 = []
      let newResult = arri1.reduce((a, b, c) => {
        let res = []
        a.map(x => {
          b.attr_values.map(y => {
            res.push({ ...x, [b.attr_name]: y })
          })
        })
        return res
      }, [{}])
      // newResult2 = Object.assign(newResult2, newResult)
      console.log(newResult, 1, newResult2)
      // 动态生成 商品规格表 
      // 编辑条件下 比较与旧版的商品规格表 把数据贴上去
      if (this.addorupdateSpecFlag == 'update') {
        newResult.forEach((el, idx) => {
          this.permissionInfo.info.goods_attr_val.forEach((m, n) => {
            //   
            let reg = /\\"/g;
            let a = JSON.stringify(m.product_attr).replace(reg, '');
            console.log(JSON.stringify(el) == a)
            if (JSON.stringify(el) == a) {
              newResult[idx] = Object.assign(m, el)
              // newResult[idx] = m
            }

          })

        })

        newResult.forEach((el, idx) => {
          let obj = {
            product_id_add: idx,
            product_bn: '',
            product_attr: { "颜色": "白色", "大小": "黑色" },
            product_is_def: 1,
            product_image: '',
            product_price: '',
            product_cost_price: '',
            product_market_price: '',
            product_stock: ''
          }
          let a = { ...obj, ...el }
          newResult2.push(a)
        })
        console.log(newResult, 2)

        this.goodSpecstableData = newResult2
      } else {
        this.goodSpecstableData = newResult2
      }
      this.goods_specs.ischooseModehow = false
    },
    changeGoodsSpecs(e, i) {
      this.goods_specs.goods_specs_values_list = []
      // goods_specs.new_goods_specs_list.push
      let self = this
      this.goods_specs.goods_specs_list.forEach(el => {
        if (el.id == e) {
          self.goods_specs.goods_specs_values_list = el.attr_values
          self.goods_specs.new_goods_specs_list.push(el)
        }
      })
      // console.log(this.goods_specs.goods_specs_values_list, "goods_specs_values_list");
      // console.log(self.goods_specs.goods_specs_keys_list, " self.goods_specs.goods_specs_keys_list");
    },
    onqyChange(e, it, idex) {
      console.log(this.goods_specs.goods_specs_values_list);
      let self = this
      self.goods_specs.goods_specs_values_list[idex] = e.target.innerHTML
    },
    legalObjadd() {
      let flag = false
      let self = this
      this.goods_specs.goods_specs_values_list.forEach(el => {
        if (!el) {
          flag = true
        }
      })
      if (flag && self.goods_specs.goods_specs_values_list.length > 0) {
        this.$warning({
          title: '提示',
          content: h => {
            return h('div', [
              h(
                'span',
                [' 信息尚未填写，请完善内容后进入下一步。']
              ),
            ])
          },

          okText: '好的',
          okType: 'primary',
          // cancelText: '取消',
          onOk() {
            // self.qystep = 2
          },
        });
        return false
      }
      console.log(this.goods_specs.goods_specs_values_list);
      this.goods_specs.goods_specs_values_list.push('')
    },
    addnewgoodsspecs(idx, n) {
      let flag = false
      let self = this
      this.goods_specs.new_goods_specs_list[idx].attr_values.forEach(el => {
        if (!el) {
          flag = true
        }
      })
      if (flag && self.goods_specs.new_goods_specs_list[idx].attr_values.length > 0) {
        this.$warning({
          title: '提示',
          content: h => {
            return h('div', [
              h(
                'span',
                [' 信息尚未填写，请完善内容后操作。']
              ),
            ])
          },

          okText: '好的',
          okType: 'primary',
          // cancelText: '取消',
          onOk() {
          },
        });
        return false
      }
      console.log(this.goods_specs.new_goods_specs_list[idx].attr_values);
      this.goods_specs.new_goods_specs_list[idx].attr_values.push('')
    },
    // 删除新增的规格名 规格值
    deleteNewspecs(e) {
      // delete this.goods_specs.new_goods_specs_list[e]
      this.goods_specs.new_goods_specs_list.splice(e, 1)
      console.log(this.goods_specs.new_goods_specs_list);
    },
    closegoodsSpecsValues(e) {
      this.goods_specs.goods_specs_values_list.splice(e, 1)
      console.log(this.goods_specs.goods_specs_values_list, "this.goods_specs.goods_specs_values_list");
    },
    deletenewGoodsSpecs(idx, n) {
      this.goods_specs.new_goods_specs_list[idx].attr_values.splice(n, 1)
    },
    // 批量生成规格表格  设置 单价 和 货品码
    createdSpecsTable() {
      let self = this
      // this.goodSpecstableData  
      this.goodSpecstableData.forEach((el, idx) => {
        self.goodSpecstableData[idx].product_price = self.goods_specs.price
        self.goodSpecstableData[idx].product_bn = self.goods_specs.barcode
        // el.product_price = this.goods_specs.price
        // el.product_bn = this.goods_specs.barcode
      })
    },
    // 改变 某一个单价/条码
    changeSingeSpecIpt(record, i, flag) {
      console.log(record, i, flag, "val,record");
      //   
      console.log(this.goodSpecstableData, 'goodSpecstableData')
      if (flag) {
        this.goodSpecsSingletableData.forEach((el, idx) => {
          if (el.product_id == record.product_id || el.product_id_add == record.product_id_add) {
            this.goodSpecsSingletableData[idx][i] = record[i]
          }
        })
      } else {
        this.goodSpecstableData.forEach((el, idx) => {
          if (el.product_id == record.product_id || el.product_id_add == record.product_id_add) {
            this.goodSpecsSingletableData[idx][i] = record[i]
          }
        })
      }
    },
    changeSingeSpecIpt1(e, i, j) {
      console.log(e, i, j);
    },
    getUser(e) {
      console.log(e);
      this.permissionInfo.info.goods_content = e
    },

    // 清除 类别  分类 品牌  状态 恢复 初始值
    clearfliterAll() {
      // 清除分类
      this.flitList[1].value = []
      this.selectedKeys = []
      this.expandedKeys = []
      this.autoExpandParent = false
      this.backupsExpandedKeys = []
      this.permissionInfo.info.permission_id_list = []

      // // 清除状态
      // this.activeStatus_status = []
      // // 清除类别
      // this.activeStatus_catagory = []
      // // 清除品牌
      // this.activeStatus_brand = [] 
      this.flitList = [
        { key: '类别', value: [], paramas: 'goods_type' },
        { key: '分类', value: [], paramas: 'goods_category' },
        { key: '状态', value: [], paramas: 'is_show' },
        { key: '品牌', value: [], paramas: 'goods_brand' },
        { key: '上架时间', value: [], paramas: 'data_time' },
        { key: '下架时间', value: [], paramas: 'data_time' },
      ]
      this.isAllLength = ""
      this.query = {
        goods_name: undefined,//商品名称
        goods_bn: undefined,//商品码
        goods_type: undefined, //1客装 2院装 不传全部
        is_show: undefined, //1在售 0停售 不传为全部
        goods_brand: undefined, //品牌id 不传为全部
        goods_category: undefined, //分类id 不传为全部
        limit: this.query.limit,
        page: 1,
      },
        // todu 调取更新list的接口
        this.getList()
    },
    clearfliterSingle(e) {
      console.log(e);
      if (e) {
        // 单个清除 根据业务 去除 
        this.query[e.paramas] = undefined
        this.flitList.forEach(el => {
          if (el.paramas == e.paramas) {
            el.value = []
          }
        })
        // 分类筛选项
        if (e.key == "分类") {
          this.permissionInfo.info.permission_id_list = this.permission_id_list = this.permission_id_list2 = this.permission_id_list3 = []
        }
        if (e.key == "上架时间" || e.key == "下架时间") {
          this.query.start_data = undefined
          this.query.end_data = undefined
        }
        this.query.page = 1
        this.getList()
      } else {
        this.permissionInfo.info.permission_id_list = this.permission_id_list = this.permission_id_list2 = this.permission_id_list3 = []
        // 全部清除
        this.clearfliterAll()
      }

    },
    // getTypestreeListSearch
    getTypestreeListSearch(e) {

    },

    // 搜索框配合 分类 树形
    getTypestreeListSearch(e) {
      console.log(e);
      var _this = this;
      //添加这行代码是为了只点击搜索才触发
      _this.searchValue = e.value;
      //如果搜索值为空，则不展开任何项，expandedKeys置为空
      //如果搜索值不位空，则expandedKeys的值要为搜索值的父亲及其所有祖先
      if (_this.searchValue === '') {
        _this.expandedKeys = [];
      } else {
        //首先将展开项与展开项副本置为空
        _this.expandedKeys = [];
        _this.backupsExpandedKeys = [];
        //获取所有存在searchValue的节点
        let candidateKeysList = _this.getkeyList(_this.searchValue, _this.treeData, []);
        //遍历满足条件的所有节点
        candidateKeysList.map(
          item => {
            //获取每个节点的母亲节点
            var key = _this.getParentKey(item, _this.treeData);
            //当item是最高一级，父key为undefined，将不放入到数组中
            //如果母亲已存在于数组中，也不放入到数组中
            if (key && !_this.backupsExpandedKeys.some(item => item === key))
              _this.backupsExpandedKeys.push(key);
          }
        )
        let length = this.backupsExpandedKeys.length;
        for (let i = 0; i < length; i++) {
          _this.getAllParentKey(_this.backupsExpandedKeys[i], _this.treeData)
        }
        _this.expandedKeys = _this.backupsExpandedKeys.slice();
      }
    },
    //获取节点中含有value的所有key集合
    getkeyList(value, tree, keyList) {
      //遍历所有同一级的树
      for (let i = 0; i < tree.length; i++) {
        let node = tree[i];
        //如果该节点存在value值则push
        if (node.category_name.indexOf(value) > -1) {
          keyList.push(node.category_id);
        }
        //如果拥有孩子继续遍历
        if (node.children) {
          this.getkeyList(value, node.children, keyList);
        }
      }
      //因为是引用类型，所有每次递归操作的都是同一个数组
      return keyList;
    },
    //该递归主要用于获取key的父亲节点的key值
    getParentKey(key, tree) {
      let parentKey, temp;
      //遍历同级节点
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
          //如果该节点的孩子中存在该key值，则该节点就是我们要找的父亲节点
          //如果不存在，继续遍历其子节点
          if (node.children.some(item => item.category_id === key)) {
            parentKey = node.category_id;
          } else if (temp = this.getParentKey(key, node.children)) {
            //parentKey = this.getParentKey(key,node.children)
            //改进，避免二次遍历
            parentKey = temp;
          }
        }
      }
      return parentKey;
    },
    //获取该节点的所有祖先节点
    getAllParentKey(key, tree) {
      var parentKey;
      if (key) {
        //获得父亲节点
        parentKey = this.getParentKey(key, tree);
        if (parentKey) {
          //如果父亲节点存在，判断是否已经存在于展开列表里，不存在就进行push
          if (!this.backupsExpandedKeys.some(item => item === parentKey)) {
            this.backupsExpandedKeys.push(parentKey);
          }
          //继续向上查找祖先节点
          this.getAllParentKey(parentKey, tree);
        }
      }
    },
    onExpand(expandedKeys) {
      //用户点击展开时，取消自动展开效果
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    onCheck(checkedKeys, info) {
      console.log(info.node.dataRef);
      console.log('onCheck', checkedKeys);
      this.CheckSureObj = {
        checkedKeys: checkedKeys,
        info: info,
        tabs: this.CheckSureObj.tabs
      }
    },
    onCheckSure() {
      let { checkedKeys, info, tabs } = this.CheckSureObj
      let that = this
      that.permissionInfo.info.permission_id_list = []
      that.flitList[1].value = []
      that.flitList[1].paramas = 'finance_category_id'

      let arrobj = []
      if (info) {
        info.checkedNodes.forEach(el => {
          let a = that.CheckSureObj.tabs == 1 ? el.data.props.finance_category_id : that.CheckSureObj.tabs == 2 ? el.data.props.category_id : el.data.props.store_category_id
          // that.permissionInfo.info.permission_id_list.push(a)
          arrobj.push(a)
          that.flitList[1].value.push(el.data.props.category_name)
        })
      }
      if (that.CheckSureObj.tabs == 1) {
        that.query.goods_category = undefined
        that.query.store_category_id = undefined
        that.flitList[1].paramas = 'finance_category_id'
        that.query.finance_category_id = arrobj
      }
      if (that.CheckSureObj.tabs == 2) {
        that.query.finance_category_id = undefined
        that.query.store_category_id = undefined
        that.flitList[1].paramas = 'goods_category'
        that.query.goods_category = arrobj
      }
      if (that.CheckSureObj.tabs == 3) {

        that.query.finance_category_id = undefined
        that.query.goods_category = undefined
        that.flitList[1].paramas = 'store_category_id'

        that.query.store_category_id = arrobj
      }
      that.statusvisible = false;

      console.log(that.query);
      that.query.page = 1
      that.getList()
    },
    getTitleList(arr, tree) {
      let newArr = this.treeToArray(tree)
      arr.forEach((el, idx) => {
        newArr.forEach((m, n) => {
          if (m.category_id == el) {
            this.flitList[1].value.push(m.category_name)
          }
        })
      })
      return newArr
    },
    // 数据扁平化
    treeToArray(tree) {
      return tree.reduce((res, item) => {
        const { children, ...i } = item
        return res.concat(i, children && children.length ? this.treeToArray(children) : [])
      }, [])
    },
    onSelect(selectedKeys, info) {
      console.log('onSelect', info);
      this.selectedKeys = selectedKeys;
    },
    getbrandsItem(i, v) {
      //   
      if (i == 'catagory') {
        console.log(this.activeStatus_catagory);
        this.flitList[0].value = [v.text]
        let a = ''
        a = v.text == '客装' ? [1, 3] : v.text == '院装' ? [2, 4] : []
        if (v.text == '全部') {
          let arr = []
          this.filters_categorys.forEach(el => {
            if (el.text != '全部') {
              arr.push(el.text)
            }
          })
          // this.activeStatus_catagory = arr
          this.flitList[0].value = arr
          a = [1, 2, 3, 4]
        }

        this.query.goods_type = a
      }
      if (i == 'status') {
        console.log(this.activeStatus_status);
        // this.activeStatus_status = [v.text]
        this.flitList[2].value = [v.text]
        let a = ''
        a = v.text == '在售' ? 1 : 0
        if (v.text == '全部') {
          let arr = []
          this.filters_status.forEach(el => {
            if (el.text != '全部') {
              arr.push(el.text)
            }
          })
          // this.activeStatus_status = arr
          this.flitList[2].value = arr
          a = undefined
        }
        this.query.is_show = a
      }
      if (i == 'brands') {
        this.visiblePlan = false;
        // this.activeStatus_brand = [v.brand_name]
        // this.flitList[3].value = [i]
        console.log(v, this.activeStatus_brand);
        // if (v == 99999) {
        v = [v]
        let arr = []
        let arr2 = []
        let arr3 = []
        this.brandsList.forEach(el => {
          if (el.brand_id != 99999) {
            arr.push(el.brand_name)
            arr3.push(el.brand_id)
          }
          v.forEach(m => {
            if (m == el.brand_id && m != 9999) {
              arr2.push(el.brand_name)
            }
          })
        })
        if (v == 99999) {
          this.flitList[3].value = arr
        } else {
          this.flitList[3].value = arr2
        }
        // this.query.goods_brand = v.includes(99999) ? arr3 : v
      }
      this.query.page = 1
      // 发送请求 
      this.getList()
    },
    // 授权机构 搜索
    getListSearchlimits(e) {
      console.log(e);
      // 根据条件 判断是机构简称1 机构代码2 机构名称3 的搜索条件
      this.checkLimitsListfn()

    },
    checkLimitsListfn(e) {

    },
    // 授权穿梭框相关
    onlimitsChange(nextTargetKeys) {
      this.limitsModel.targetKeys = nextTargetKeys;
    },
    onlimitsChange2(nextTargetKeys) {

      console.log(this.typesModel);
      this.typesModel.targetKeys = nextTargetKeys;
    },
    onChecked1(_, e, checkedKeys, itemSelect) {
      const { eventKey } = e.node;
      itemSelect(eventKey, !isChecked(checkedKeys, eventKey));
    },
    triggerDisable(disabled) {
      this.disabled = disabled;
    },

    triggerShowSearch(showSearch) {
      this.showSearch = showSearch;
    },
    getRowSelection({ disabled, selectedKeys, itemSelectAll, itemSelect }) {
      return {
        getCheckboxProps: item => ({ props: { disabled: disabled || item.disabled } }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter(item => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, selectedKeys)
            : difference(selectedKeys, treeSelectedKeys);
          itemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          itemSelect(key, selected);
        },
        selectedRowKeys: selectedKeys,
      };
    },

    // 树形结构 转 平铺 从外到内依次递归，有 children 则继续递归
    treeToArr(data, pid = null, res = []) {
      data.forEach(item => {
        res.push({ pid: pid, id: item.id, name: item.name });
        if (item.children && item.children.length !== 0) {
          this.treeToArr(item.children, item.id, res);
        }
      });
      return res;
      // 
    },
    // 逆向 平铺-转-树形结构 ，有 children 则继续递归
    arrToTree(arr, pid = null) {
      const res = [];
      arr.forEach(item => {
        if (item.pid === pid) {
          // 这样每次都需要遍历整个数组，因此时间复杂度为 n*n
          // const children = arrToTree(arr, item.id)   

          // 往下递归时，每次数组的大小都在减小，每次都筛选掉父代元素，最终的时间复杂度为 n*logn
          const children = this.arrToTree(arr.filter(v => v.pid !== pid), item.id);
          if (children.length) {
            res.push({ ...item, children })
          } else {
            res.push({ ...item })
          }
        }
      });
      return res;
    },
    // 在售时间确认
    openTimes(record) {
      // 调取 编辑接口
      this.opentimes.visible = true
      this.opentimes.data.goods_id = record.goods_id
      // this.opentimes.goods_shou_type = 1
      this.opentimes.data.goods_shou_type = 1
      // this.permissionInfo.info.goods_shou_type='' //销售时间 1长期 （默认） 2截止
      // this.permissionInfo.info.goods_shou_end_time ='' //截止时间 戳

    },
    updataOpentime() {
      let data = this.opentimes.data
      UpdateGoods(data).then(res => {
        if (res.error_code == 0) {
          this.$message.success(res.data.message)
          this.opentimes.visible = false
          this.query.page = 1
          this.getList()
        }
      })
    },
    onChangeopentime(e, i) {
      console.log(e, i);
      this.opentimes.data.goods_shou_type = e.target.value
    },
    // 停售 在售
    stopSell(record, i) {
      let data = {
        goods_id: record.goods_id,
        is_show: i,
      }
      UpdateGoods(data).then(res => {
        if (res.error_code == 0) {
          this.$message.success(res.data.message)
          this.query.page = 1
          this.getList()
        }
      })
    },

    // 导入 导出
    importsFn() { },
    exportsFn() { },
    offset() {


      this.CheckSureObj.info = undefined
      this.CheckSureObj.checkedKeys = undefined
      this.query.finance_category_id = undefined;
      this.query.store_category_id = undefined;
      this.query.category_id = [];
      this.permission_id_list = [];
      this.permission_id_list2 = [];
      this.permission_id_list3 = [];
      this.permissionInfo.info.permission_id_list = [];
      this.expandedKeys = [];
      this.selectedKeys = [];
      this.selectedKeys2 = [];
      this.selectedKeys3 = [];
      this.permission_id_list = []
      this.permission_id_list2 = []
      this.permission_id_list3 = []
      this.query.page = 1
      this.flitList[1].value = '';
      this.query.goods_category = undefined //门店分类 不传为全部 

      this.getList()

      this.$forceUpdate();
    },
    setMonth(date, dateString) {

      this.visibleTime = false;
      this.query.start_data = dateString[0]
      this.query.end_data = dateString[1]
      this.flitList[4].value = [this.query.start_data + ' ~ ' + this.query.end_data]
      // this.query.dividend_month = dateString;
      this.getList();
    },
    makesurerefund() {
      // 退款接口 数据  refund:
      this.refund.refundvisible = false
    },
    makesuresendout() {
      // 发货 数据  sendout:
      this.sendout.sendoutvisible = false
    },
    // 添加包裹
    addBg() {
      let id
      if (this.sendout.form && this.sendout.form.length) {
        id = this.sendout.form[this.sendout.form.length - 1].id++
      } else {
        id = 0
      }
      this.sendout.form.push(
        { tracking: undefined, id: id, company_id: undefined }
      )
    },
    closex(it) {
      this.sendout.form.forEach((el, idx) => {
        if (el.id == it.id) {
          this.sendout.form.splice(this.sendout.form[idx], 1);
        }
      })
    },
    onChangeprivince(e, i) {
      console.log(e, i);
    },
  },
  watch: {

  },

}
</script>
<style lang="less" scoped>
.personnelfiles {
  // padding: 12px;
  margin: 18px 12px 18px 18px;
  // margin: 0;
  padding: 0;
  overflow: hidden;
  // height: calc(100vh - 52px - 49px);
  // padding: 20px 0;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  height: calc(100% - 86px);
  .filter-wrap {
    background: #fff;
    border-radius: 4px 4px 0 0;
    height: 66px;
    display: flex;
    z-index: 1;
    align-items: center;
    margin-bottom: 7px;
    // justify-content: space-between;
    // &::before{
    //   content: none;

    // }
  }
  /deep/ .ant-checkbox-wrapper {
    background: transparent;
    border: none;
    box-shadow: none;
  }
}
.borderbox {
  margin-left: 4px;
  display: inline-block;
  border-radius: 6px;
  text-align: center;
  width: 18px;
  height: 18px;
  // background: @btn2mianbgDColor;
  // box-shadow: 0px 2px 3px 0px @btn2mianOutDColor,
  //   inset 0px -2px 0px 0px @btn2mianInsertDColor;
  border-radius: 4px;
  // border: 1px solid @btn2mianboderDColor;
  line-height: 14px;

  // display: flex;
  // align-items: center;
  // justify-content: center;
}
.bigdropdown_imga {
  display: none;
}
.bigdropdown_img1a {
  display: none;
}
.bigdropdown {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 26px;
  // background: #f2f5f5;
  border-radius: 4px;
  line-height: 26px;
  padding: 0 8px 0 8px;
  &:hover {
    background: #f2f5f5;
  }
  // .borderbox {
  //   position: absolute;
  //   top: 4px;
  //   left: 55px;
  .meiye-xiala {
    position: absolute;
    top: 2px;
    left: 59px;
    font-size: 12px;
    margin-top: -1px;
    // transform: scale(0.83);
    display: inline-block;
    // color: @btn2mianfontDColor;
    color: @fontColor4;
  }
  // &:hover {
  //   // border: 1px solid @btn2mianboderHColor;
  //   .meiye-xiala {
  //     position: absolute;
  //     top: 1px;
  //     left: 64px;
  //     font-size: 8px;
  //     margin-top: -1px;
  //     transform: scale(0.83);
  //     display: inline-block;
  //     // color: @btn2mianfontHColor;
  //     color: #414041;
  //     transform: scale(0.83) rotate(180deg);
  //   }
  // }
  // }
}
/deep/ .bgchangenode {
  background: #ccc;
}
/deep/.ant-modal-header {
  padding-left: 24px;
}
/deep/.ant-modal-footer {
  // padding-bottom: 32px;
}
.btn-padding {
  // display: none;
  // background: rgba(216, 219, 236, 0.27);
  border-radius: 4px;
  color: @btn3mianFontDColor;
  // background: #f5f5fa;
  border: none !important;
  box-shadow: none !important;
  &:hover {
    color: @btn3mianFontHColor !important;
    // background-color: @btn3mianbgHColor !important;
    border: none !important;
    box-shadow: none !important;
  }
  &:focus {
    color: @btn3mianFontFColor !important;
    // background-color: @btn3mianbgFColor !important;
    border: none !important;
    box-shadow: none !important;
  }
  // border: 1px solid @btn2mianboderDColor;
  // box-shadow: 0px 2px 4px 0px @btn2mianOutDColor, inset 0px -2px 0px 0px @btn2mianInsertDColor;
  // background-color: @btn2mianbgDColor;
  // color: @btn2mianfontDColor;
  // &:active {
  //   color: @btn2mianfontFColor !important;
  //   border: 1px solid @btn2mianboderFColor !important;
  //   background-color: @btn2mianbgFColor !important;
  //   box-shadow: 0px 2px 4px 0px @btn2mianOutFColor, inset 0px -2px 0px 0px @btn2mianInsertFColor !important;
  // }

  // &:focus {
  //   color: @btn2mianfontFColor !important;
  //   border: 1px solid @btn2mianboderFColor !important;
  //   background-color: @btn2mianbgFColor;
  //   box-shadow: 0px 2px 4px 0px @btn2mianOutFColor, inset 0px -2px 0px 0px @btn2mianInsertFColor !important;
  // }

  // &:hover {
  //   color: @btn2mianfontHColor !important;
  //   border: 1px solid @btn2mianboderHColor !important;
  //   background-color: @btn2mianbgHColor;
  //   box-shadow: 0px 2px 4px 0px @btn2mianOutHColor, inset 0px -2px 0px 0px @btn2mianInsertHColor;
  // }
}
/deep/ .ant-table-header {
  // margin-top: -23px;
}
/deep/.bigdropdown .ant-dropdown-open {
  .meiye-xiala {
    position: absolute;
    top: 1px;
    left: 61px;
    font-size: 12px;
    margin-top: -1px;
    // transform: scale(0.83);
    display: inline-block;
    // color: @btn2mianfontHColor;
    color: #414041;
    transform: rotate(180deg);
  }
}
/deep/.bigdropdown3 .ant-dropdown-open {
  .meiye-xiala {
    left: 84px !important;
  }
}
/deep/.wid-102 .ant-dropdown-open {
  .meiye-xiala {
    position: absolute;
    top: 1px;
    left: 86px !important;
    font-size: 12px;
    margin-top: -1px;
    // transform: scale(0.83);
    display: inline-block;
    // color: @btn2mianfontHColor;
    color: #414041;
    transform: rotate(180deg);
  }
}
/deep/.bigdropdown .ant-popover-open {
  .meiye-xiala {
    position: absolute;
    top: 1px;
    left: 56px;
    font-size: 8px;
    margin-top: -1px;
    display: inline-block;
    // color: @btn2mianfontHColor;
    color: #414041;
    transform: rotate(180deg);
    margin-left: 3px;
  }
}
/deep/.bigdropdown3 .ant-popover-open {
  .meiye-xiala {
    position: absolute;
    top: 1px;
    left: 84px;
    font-size: 8px;
    margin-top: -1px;
    display: inline-block;
    // color: @btn2mianfontHColor;
    color: #414041;
    transform: rotate(180deg);
    margin-left: 3px;
  }
}
/deep/
  .ant-select-dropdown-menu-item:hover:not(
    .ant-select-dropdown-menu-item-disabled
  ),
.ant-select-dropdown-menu-item-active:not(
    .ant-select-dropdown-menu-item-disabled
  ),
.ant-cascader-menu-item:hover,
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover,
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-tree li .ant-tree-node-content-wrapper:hover,
.ant-select-tree li .ant-select-tree-node-content-wrapper:hover {
  background-color: @selectdownbgHColor !important;
  // color: #0A955D;
  color: @selectdownfontHColor;
  font-weight: 400;
}
.smallbtnlong {
  letter-spacing: -1px !important;
}

.meiye-icon {
  color: @primaryColor;
}
/deep/ .ant-input2 .ant-input {
  padding-top: 5px;
  &::placeholder {
    padding-top: 3px;
  }
}
.linebox {
  display: flex;
  padding: 12px 0;

  .lfbox {
    width: 110px;
    font-size: 14px;
    color: @fontColor5;
    text-align: left;
    .imgxing {
      width: 8px;
      height: 22px;
      color: red;
    }
  }

  .rgbox {
    font-size: 14px;
    color: @btnColorfont1;
    // width: calc(100% - 120px);
    position: relative;
    width: 542px;

    .tuijia {
      font-size: 14px;
      color: @fontColor6;
    }
  }
}
/deep/ .ant-upload-picture-card-wrapper .ant-upload {
  width: 45px !important;
  height: 45px !important;
  padding: 0;
}
/deep/ .ant-upload-select-picture-card i {
  font-size: 14px;
}
.constmodel {
  min-height: 420px;
}
.chooseguige {
  margin-right: 120px;
  margin-left: 12px;
  &:hover {
    color: @primaryColor;
  }
}
.allsetguige {
  &:hover {
    color: @primaryColor;
  }
}
.guige_values_options {
  position: relative;
  margin-right: 20px;
  // .
  i {
    position: absolute;
    right: -8px;
    top: 0;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.13);
  }
}
.closeIcon {
  position: absolute;
  right: -8px;
  top: 0;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.13);
}
.guige_values {
  margin: 0 28px;
  flex-wrap: wrap;
  .guige_values_options {
    margin-bottom: 20px;
  }
}
.goods_specx_add {
  margin: 0 28px 12px;
}
.goods_editor {
  // width: 582px;
  width: 432px;
  height: 320px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #ccc;
  overflow: hidden;
  overflow-y: auto;
}
/deep/ .myFileList .ant-spin-nested-loading {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  min-height: 40px;
}
/deep/ .guigeTable .ant-spin-nested-loading {
  min-height: 40px;
}
/deep/.ant-table-pagination.ant-pagination {
  height: 65px;
}

/deep/ .ant-table-header .ant-checkbox {
  width: 14px;
  height: 14px;
}
/deep/ .table-manage .ant-table-header .ant-checkbox-inner {
  // display: none;
}

/deep/.table-manage .ant-table-tbody .ant-checkbox-inner {
  // display: none;
}
/deep/
  .table-manage
  .ant-table-tbody
  .ant-checkbox-wrapper
  .ant-checkbox-checked
  .ant-checkbox-inner {
  display: block !important;
}
.table-manage {
  margin-top: -1px;
}
/deep/ .table-manage .ant-table-selection-column:hover .ant-checkbox-inner {
  display: block !important;
}
/deep/.tableBox .ant-table-thead .ant-table-selection-column span:nth-child(1) {
  display: inline-block;
}
/deep/.tableBox .ant-table-body .ant-table-selection-column span:nth-child(1) {
  display: none;
}
.meiye-xiala {
  color: @fontColor4;
}

// 复合搜索框
.owninputbox {
  display: inline-block;
  background: #f5f7f7;
  width: 401px;
  height: 36px;
  line-height: 36px;
  background: #f5f7f7;
  box-shadow: inset 0px 3px 0px 0px rgba(76, 124, 121, 0.1);
  border-radius: 4px;
  border: 1px solid #b0c3c2;
  margin-right: 20px;
  .meiye-icon {
    color: @fontColor4;
  }
  .meiye-sousuo {
    color: @fontColor5;
  }
  .owninputbox_selested {
    cursor: pointer;
    width: 103px;
    border-right: 1px solid #b0c3c2;
    display: inline-block;
    height: 22px;
    font-size: 16px;
    font-weight: 400;
    color: @fontColor4;
    line-height: 22px;
    position: relative;
    &:hover {
      .meiye-icon {
        color: @primaryColor;
      }
    }
    // padding-left: 8px;
  }
  .owninputbox_search {
    box-shadow: none;

    // i {
    //   position: absolute;
    //   right: 12px;
    //   top: 2px;
    // }
  }
  .ant-input2 {
    height: 22px;
    border: none !important;
    box-shadow: none !important;
    background: #f5f7f7;
    /deep/ .ant-input {
      height: 22px;
      border: none !important;
      box-shadow: none !important;
      background: transparent;
    }
  }
  &:hover {
    border: 1px solid @primaryColor;
    .meiye-sousuo {
      color: @primaryColor;
    }
  }
}
.datas {
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: @fontColor5;
  line-height: 22px;
  margin-left: 12px;
}
.baseColor11 {
  color: @baseColor11;
}
.baseColor1 {
  color: red;
}
.filter {
  margin-top: -9px;
  padding-bottom: 6px;
  background: #fff;
}
.filter-wrap2 {
  background: #fff;
  height: 55px;
  padding: 18px 44px 0 22px;
  align-items: center;
  .filter_sx {
    font-size: 16px;
    font-weight: 400;
    color: @fontColor3;
    height: 16px;
    line-height: 16px;
    border-right: 1px solid #dadada;
    padding-right: 12px;
    margin-right: 18px;
  }
  .filter_type {
    height: 24px;
    background: #f3fafa;
    border-radius: 4px;
    padding: 2px 6px;
    font-size: 14px;
    font-weight: 400;
    color: @fontColor4;
    line-height: 24px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    .typeitems {
      font-size: 14px;
      font-weight: 400;
      color: @baseColor11;
      display: flex;
      align-items: center;
      .overflowElip {
        display: inline-block;
        max-width: 120px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .meiye-close-bold {
        font-size: 9px;
        color: #578380;
        margin: 2px 0 0 4px;
      }
    }
  }
  .filter_clear {
    cursor: pointer;
    .meiye-routeicon-ashbin {
      color: @fontColor5;
      font-size: 10px;
    }
  }
}
.meiye-leixing,
.meiye-zhuangtai,
.meiye-fenlei,
.meiye-pinpai {
  color: rgb(158, 166, 164);
  font-size: 15px;
}

.typesbox {
  width: 358px;
  height: 368px;
  background: #ffffff;
  box-shadow: 0px 6px 8px 0px rgba(99, 161, 148, 0.08),
    0px 12px 24px 0px rgba(0, 0, 0, 0.08),
    inset 0px -3px 0px 0px rgba(205, 231, 231, 0.49);
  border-radius: 6px;
  border: 1px solid #e4e9e9;
  display: flex;
  flex-direction: column;
  .typesHead {
    margin: 12px;
  }
  .typesBody {
    flex: 1;
    overflow: auto;
    margin: 0 12px;
  }
  .typesFooter {
    width: 356px;
    height: 58px;
    line-height: 58px;
    border-top: 1px solid #e8edec;
    background: #ffffff;
    padding: 0px 12px;
    align-items: center;
    justify-content: space-between;
    /deep/ .ant-btn {
      height: 32px;
    }
  }
}
.btn3 {
  border-radius: 4px;
  color: @btn3mianFontDColor;
  background: #f5f5fa;
  border: none !important;
  box-shadow: none !important;
  &:hover {
    color: @btn3mianFontHColor !important;
    background-color: @btn3mianbgHColor !important;
    border: none !important;
    box-shadow: none !important;
  }
  &:focus {
    color: @btn3mianFontFColor !important;
    background-color: @btn3mianbgFColor !important;
    border: none !important;
    box-shadow: none !important;
  }
}
.exports_setxt {
  width: 100%;
  height: 42px;
  border-radius: 4px;
  border: 1px dashed #ccdada;
  text-align: center;
  line-height: 42px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  cursor: pointer;

  &:hover {
    border: 1px dashed @primaryColor;
    color: @baseColor11;
  }
}
.exports_setxt2_box {
  width: 100%;
  height: 237px;
  overflow-y: auto;
  background: #fafcfc;
  border-radius: 4px;
  border: 1px dashed #ccdada;
  font-size: 14px;
  font-weight: 400;
  color: @fontColor4;
  padding: 12px 16px;
}
/deep/.filter-wrap2 .filter_sx {
  color: @fontColor3 !important;
}
/deep/.typesBody .ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  display: none;
}
.plbtn {
  width: 101px;
  position: relative;
  text-align: left;
  padding-right: 30px;
  .meiye-xiala {
    margin-left: 5px;
    font-size: 12px;
    position: absolute;
    right: 15px;
    top: 9px;
  }
  &:hover {
    .meiye-xiala {
      color: @baseColor11;
      transform: rotate(180deg);
      font-size: 12px;
      position: absolute;
      right: 14px;
      top: 9px;
    }
  }
}
.ant-dropdown-open {
  // color: @baseColor11 !important;
}
.ant-dropdown-open .meiye-xiala {
  transform: rotate(180deg) !important;
  position: absolute;
  right: 14px;
  top: 9px;
}
/deep/.filter_clear {
  color: @fontColor5;
  display: inline-block;
  height: 24px;
  width: 46px;
  line-height: 24px;
  position: relative;
  text-align: right;
}
/deep/.filter_clear .meiye-shanchu {
  font-size: 14px;
  // line-height: 20px;
  position: absolute;
  left: 0;
  top: 1px;
}
/deep/.ant-popover-inner-content {
  padding: 11px 11px 11px 14px;
  //
}
/deep/.ant-calendar-picker.jzpicker {
  // margin: 0 14px 0 11px;
}
.wid-102 {
  width: 102px;
  .meiye-xiala {
    left: 84px;
  }
}
.saleslip_tabs {
  padding-top: 16px;
  /deep/ .ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
    // padding: 8px 8px 8px 8px;
    white-space: break-spaces;
    padding: 0px 8px;
    height: 28px !important;
    font-size: 14px !important;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: @fontColor4;

    &::before {
      display: none;
    }
  }
  /deep/ .ant-tabs-nav .ant-tabs-tab {
    // color: #819190;
  }
  /deep/.ant-tabs-nav .ant-tabs-tab-active {
    color: @baseColor11;
  }
  /deep/.ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar,
  .ant-tabs .ant-tabs-right-bar .ant-tabs-ink-bar {
    width: 2px;
  }
  /deep/.ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar {
    right: 1px;
    background: @baseColor11 !important;
  }
  /deep/.ant-tabs .ant-tabs-left-bar {
    border-right: 1px solid #bfd6d4;
  }
}
.personnelfiles {
  .saleslip_tabs {
    background: #fff;
    padding-bottom: 26px;
    /deep/ .default_tabs.ant-tabs .ant-tabs-tab::before {
      display: none;
    }
    /deep/ .default_tabs.ant-tabs .ant-tabs-bar {
      height: 38px !important;
      padding: 0 0 0 24px !important;
      line-height: 38px !important;
    }
    /deep/ .default_tabs.ant-tabs .ant-tabs-tab {
      text-shadow: 0 0 0.25px currentColor;
      position: relative;
      display: inline-block;
      box-sizing: border-box;
      height: 39px !important;
      padding: 0 !important;
      line-height: 38px !important;
      margin: 0 32px 0 0;
      padding: 0;
      text-decoration: none;
      cursor: pointer;
      font-size: 16px !important;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: @fontColor4;
    }
    /deep/.ant-tabs-tab-active::before {
      display: none;
    }
    /deep/ .ant-tabs-left-no-ctn {
      /deep/ .ant-tabs-left-bar {
        border-right: 2px solid #bfd6d4;
        float: left;
        margin-right: -1px;
        margin-bottom: 0;
        height: 100%;
        border-bottom: 0;
      }
      /deep/ .ant-tabs-tab-active::before {
        display: none;
      }
      /deep/ .ant-tabs-tab::after {
        display: none;
      }
      // width: 74px;
      /deep/ .ant-tabs-tab {
        &::before {
          display: none;
        }
        &::after {
          display: none;
        }
      }
      .ant-tabs-bar {
        height: auto !important;
        padding-left: 0;
        padding-top: 0;
        background: transparent !important;
        border-bottom: none;
      }
    }
  }
}
.errorColor {
  color: #f35e67;
}
.font3 {
  color: @fontColor3;
}
.fontcancle {
  color: rgba(45, 56, 53, 0.6);
}
.mgl-12 {
  margin-left: 12px;
}
.mgr-4 {
  margin-right: 4px;
}
.shopdetails {
  margin-bottom: 16px;
  .shopdetails_img {
    width: 47px;
    height: 36px;
    margin-right: 8px;
    background: rgb(162, 200, 225);
  }
  .shopdetails_txt {
    p {
      margin: 0;
      padding: 0;
    }
    .shopdetails_names {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: @fontColor4;
    }
    .shopdetails_gg {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: @fontColor3;
    }
  }
}
.width-173 {
  width: 173px;
}
.addtracking {
  height: 40px;
  background: #f0f6f5;
  border-radius: 4px;
  line-height: 40px;
  .addplus {
    display: inline-block;
    width: 24px;
    text-align: center;
    line-height: 24px;
    height: 24px;
    background: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(173, 207, 203, 0.35),
      inset 0px -3px 0px 0px #dbeae8;
    border-radius: 5px;
    border: 1px solid #bacbc7;
    margin: 0 8px 0 16px;
    cursor: pointer;
  }
}
.addform {
  height: 144px;
  background: #f7fafa;
  border-radius: 4px;
  // overflow: hidden;
  // overflow-y: auto;
  margin-bottom: 30px;
  position: relative;
  padding: 30px 24px 0;

  .close-x {
    width: 20px;
    height: 20px;
    background: #f35e67;
    border-radius: 0px 4px 0px 4px;
    position: absolute;
    right: 0px;
    top: 0px;
    text-align: center;
    line-height: 21px;
    .meiye-close-bold {
      color: #fff;
    }
  }
}
</style>