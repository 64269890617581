const mockdata =
  [
    {
      "id": 1,
      "pid": 0,
      "title": "总括",
      "icon": "meiye-routeicon-home",
      "path": "",
      "component": "LayoutBase",
      "params": "",
      "children": [
        {
          "id": 2,
          "pid": 1,
          "title": "控制台",
          "icon": "meiye-routeicon-modular",
          "path": "setting",
          "component": "LayoutBlock",
          "params": "",
          "children": [
            {
              "id": 11,
              "pid": 2,
              "title": "权限管理",
              "icon": "meiye-routeicon-drag",
              "path": "permission",
              "component": "permission",
              "params": ""
            },
            {
              "id": 12,
              "pid": 2,
              "title": "角色管理",
              "icon": "meiye-routeicon-electronics",
              "path": "role",
              "component": "role",
              "params": ""
            },
            {
              "id": 13,
              "pid": 2,
              "title": "公司管理",
              "icon": "meiye-routeicon-work-copy",
              "path": "company",
              "component": "company",
              "params": ""
            },
            {
              "id": 14,
              "pid": 2,
              "title": "员工管理",
              "icon": "meiye-routeicon-image-text",
              "path": "staff",
              "component": "staff",
              "params": ""
            },
            {
              "id": 15,
              "pid": 2,
              "title": "短信模板",
              "icon": "meiye-routeicon-film",
              "path": "message",
              "component": "message",
              "params": ""
            }
          ]
        },
        {
          "id": 3,
          "pid": 1,
          "title": "总览",
          "icon": "meiye-routeicon-home",
          "path": "overview",
          "component": "overview",
          "params": ""
        }
      ],
      "redirect": "/overview"
    },
    {
      "id": 4,
      "pid": 0,
      "title": "数字化管理",
      "icon": "meiye-routeicon-chart-pie",
      "path": "",
      "component": "LayoutBase",
      "params": "",
      "children": [
        {
          "id": 5,
          "pid": 4,
          "title": "用户",
          "icon": "meiye-routeicon-user",
          "path": "users",
          "component": "LayoutBlock",
          "params": "",
          "children": [
            {
              "id": 10,
              "pid": 5,
              "title": "用户管理",
              "icon": "meiye-routeicon-copy",
              "path": "member",
              "component": "member",
              "params": ""
            }
          ]
        },
        {
          "id": 6,
          "pid": 4,
          "title": "商品",
          "icon": "meiye-routeicon-calendar",
          "path": "commodity",
          "component": "LayoutBlock",
          "params": "",
          "children": [
            {
              "id": 7,
              "pid": 6,
              "title": "商品分类",
              "icon": "meiye-routeicon-chart-pie",
              "path": "type",
              "component": "type",
              "params": ""
            },
            {
              "id": 8,
              "pid": 6,
              "title": "商品管理",
              "icon": "meiye-routeicon-discount",
              "path": "management",
              "component": "management",
              "params": ""
            },
            {
              "id": 9,
              "pid": 6,
              "title": "商品品牌",
              "icon": "meiye-routeicon-complete",
              "path": "brand",
              "component": "brand",
              "params": ""
            }
          ]
        },
        {
          "id": 16,
          "pid": 4,
          "title": "合伙人",
          "icon": "meiye-routeicon-data-view",
          "path": "partner",
          "component": "LayoutBlock",
          "params": "",
          "children": [
            {
              "id": 17,
              "pid": 16,
              "title": "概况",
              "icon": "meiye-routeicon-chart-pie",
              "path": "partnerMenu",
              "component": "partnerMenu",
              "params": ""
            },
            {
              "id": 18,
              "pid": 16,
              "title": "合伙人设置",
              "icon": "meiye-routeicon-copy",
              "path": "partnerMenuSetting",
              "component": "partnerMenuSetting",
              "params": ""
            },
            {
              "id": 20,
              "pid": 16,
              "title": "合伙人账户",
              "icon": "meiye-routeicon-discount",
              "path": "partnerMenuUser",
              "component": "partnerMenuUser",
              "params": ""
            },
            {
              "id": 21,
              "pid": 16,
              "title": "合伙人收益",
              "icon": "meiye-routeicon-data-view",
              "path": "partnerMenuEarnings",
              "component": "partnerMenuEarnings",
              "params": "",
              "children": [
                {
                  "id": 24,
                  "pid": 21,
                  "title": "合伙人佣金",
                  "icon": "",
                  "path": "commission",
                  "component": "commission",
                  "params": ""
                },
                {
                  "id": 25,
                  "pid": 21,
                  "title": "合伙人分红",
                  "icon": "",
                  "path": "bonus",
                  "component": "bonus",
                  "params": ""
                }
              ]
            },
            {
              "id": 22,
              "pid": 16,
              "title": "合伙人档案",
              "icon": "meiye-routeicon-complete",
              "path": "partnerMenuFile",
              "component": "partnerMenuFile",
              "params": ""
            },
            {
              "id": 26,
              "pid": 16,
              "title": "合伙人收益Ls",
              "icon": "meiye-routeicon-chart-pie",
              "path": "partnerMenuEarningsLs",
              "component": "partnerMenuEarningsLs",
              "params": "",
              "children": [
                {
                  "id": 28,
                  "pid": 26,
                  "title": "合伙人分红",
                  "icon": "meiye-routeicon-chart-pie",
                  "path": "bonusLs",
                  "component": "bonusLs",
                  "params": ""
                },
                {
                  "id": 29,
                  "pid": 26,
                  "title": "合伙人佣金",
                  "icon": "meiye-routeicon-discount",
                  "path": "commissionLs",
                  "component": "commissionLs",
                  "params": ""
                }
              ]
            },
            {
              "id": 27,
              "pid": 16,
              "title": "合伙人分红",
              "icon": "meiye-routeicon-electronics",
              "path": "bonusLs",
              "component": "bonusLs",
              "params": ""
            },
            {
              "id": 37,
              "pid": 16,
              "title": "合伙人档案Ls",
              "icon": "meiye-routeicon-comment",
              "path": "partnerMenuUserLs",
              "component": "partnerMenuUserLs",
              "params": ""
            },
            {
              "id": 38,
              "pid": 16,
              "title": "合伙人账户Ls",
              "icon": "meiye-routeicon-discount",
              "path": "partnerMenuFileLs",
              "component": "partnerMenuFileLs",
              "params": ""
            }
          ]
        }
      ]
    },
    {
      "id": 30,
      "pid": 0,
      "title": "管理中心",
      "icon": "meiye-routeicon-calendar",
      "path": "",
      "component": "LayoutBase",
      "params": "",
      "children": [
        {
          "id": 31,
          "pid": 30,
          "title": "商品中心",
          "icon": "meiye-routeicon-layers",
          "path": "shop",
          "component": "LayoutBlock",
          "params": "",
          "children": [
            {
              "id": 32,
              "pid": 31,
              "title": "产品列表",
              "icon": "meiye-routeicon-chart-pie",
              "path": "management",
              "component": "management",
              "params": "",
              "children": [
                {
                  "id": 35,
                  "pid": 32,
                  "title": "产品列表",
                  "icon": "meiye-routeicon-chart-pie",
                  "path": "managementlist",
                  "component": "management",
                  "params": ""
                },
                {
                  "id": 36,
                  "pid": 32,
                  "title": "赠送产品",
                  "icon": "meiye-routeicon-folder-close",
                  "path": "give",
                  "component": "management",
                  "params": ""
                }
              ]
            },
            {
              "id": 33,
              "pid": 31,
              "title": "品牌管理",
              "icon": "meiye-routeicon-complete",
              "path": "brand",
              "component": "brand",
              "params": ""
            },
            {
              "id": 34,
              "pid": 31,
              "title": "分类管理",
              "icon": "meiye-routeicon-chart-pie",
              "path": "type",
              "component": "type",
              "params": ""
            }
          ]
        }
      ]
    }
  ]

module.exports = {
  mockdata,

}