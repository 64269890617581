import { render, staticRenderFns } from "./deceiveddetail.vue?vue&type=template&id=958d1c90&scoped=true&"
import script from "./deceiveddetail.vue?vue&type=script&lang=js&"
export * from "./deceiveddetail.vue?vue&type=script&lang=js&"
import style0 from "./deceiveddetail.vue?vue&type=style&index=0&id=958d1c90&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "958d1c90",
  null
  
)

export default component.exports